import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';
window.onload = function() {
  var newUrl = "https://conten.tech/superfan";
  window.location.href = newUrl;
};
function App() {

  return (
    <div></div>
  );
}

export default App;
